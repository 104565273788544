// src/components/RedeemedVouchersTable.js
import React from 'react';
import '../css/RedeemedVouchersTable.css';

const RedeemedVouchersTable = ({ redeemedVouchers }) => {
  // Sort redeemed vouchers by redemption date in descending order
  const sortedRedeemedVouchers = redeemedVouchers.sort((a, b) => new Date(b.redeemedAt) - new Date(a.redeemedAt));

  return (
    <table className="redeemedVouchersTable">
      <thead>
        <tr>
          <th>Voucher #</th>
          <th>Redeem Code</th>
          <th>Voucher Value</th>
          <th>Bitcoin Value</th>
          <th>Satoshi Value</th>
          <th>Redeemed At</th>
          <th>Redeemer's Email</th>
          <th>Redeemer's Wallet Address</th>
        </tr>
      </thead>
      <tbody>
        {sortedRedeemedVouchers.map((voucher, index) => (
          <tr key={voucher.code}>
            <td>{index + 1}</td>
            <td>{voucher.code}</td>
            <td>${voucher.value}</td>
            <td>{parseFloat(voucher.bitcoinValue).toFixed(6)} BTC</td>
            <td>{(parseFloat(voucher.bitcoinValue) * 1e8).toFixed(0)} Satoshi</td>
            <td>{new Date(voucher.redeemedAt).toLocaleDateString()}</td>
            <td>{voucher.redeemerEmail}</td>
            <td>{voucher.redeemerWalletAddress}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RedeemedVouchersTable;