import React from 'react';
import '../css/VoucherTable.css';

const VoucherTable = ({ vouchers }) => {
  // Sort vouchers in reverse chronological order
  const sortedVouchers = vouchers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


  const getVoucherStatus = (voucher) => {
    if (voucher.status === 'redeemed' && voucher.redeemedAt) {
      return 'redeemed';
    } else if (voucher.status === 'sold' && voucher.purchaseDate) {
      return 'sold';
    } else {
      return 'available';
    }
  };

  return (
    <table className="voucherTable">
      <thead>
        <tr>
          <th>Voucher #</th>
          <th>Redeem Code</th>
          <th>Value</th>
          <th>Bitcoin Value</th>
          <th>Satoshi Value</th>
          <th>Created At</th>
          <th>Status</th>
          <th>Purchase Date</th>
          <th>Redeemed At</th>
          <th>Unique URL Hash</th>
        </tr>
      </thead>
      <tbody>
        {sortedVouchers.map((voucher, index) => (
          <tr key={voucher._id}>
            <td>{index + 1}</td>
            <td>{voucher.code}</td>
            <td>${voucher.value}</td>
            <td>{parseFloat(voucher.bitcoinValue).toFixed(6)} BTC</td>
            <td>{(parseFloat(voucher.bitcoinValue) * 1e8).toFixed(0)} Satoshi</td>
            <td>{new Date(voucher.createdAt).toLocaleDateString()}</td>
            <td>{getVoucherStatus(voucher)}</td>
            <td>{voucher.purchaseDate ? new Date(voucher.purchaseDate).toLocaleDateString() : '-'}</td>
            <td>{voucher.redeemedAt ? new Date(voucher.redeemedAt).toLocaleDateString() : '-'}</td>
            <td>{voucher.uniqueUrlHash}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VoucherTable;