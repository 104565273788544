import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPanel from './components/AdminPanel';
import './App.css';
import axios from 'axios';

const App = () => {
  const [vouchers, setVouchers] = useState([]);

  const fetchVouchers = async () => {
    try {
      const response = await axios.get('/api/vouchers');
      return response.data;
    } catch (error) {
      console.error('Error fetching vouchers:', error);
      return [];
    }
  };

  useEffect(() => {
    const getVouchers = async () => {
      const data = await fetchVouchers();
      setVouchers(data);
      console.log('Fetched vouchers:', data);
    };

    getVouchers();
  }, []);

  console.log('Vouchers in App component:', vouchers);

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<AdminPanel vouchers={vouchers} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;