import React, { useState, useEffect } from 'react';
import axios from 'axios';
import VoucherTable from './VoucherTable';
import RedeemedVouchersTable from './RedeemedVouchersTable';
import '../css/AdminPanel.css';

const AdminPanel = () => {
  const [vouchers, setVouchers] = useState([]);
  const [redeemedVouchers, setRedeemedVouchers] = useState([]);
  const [selectedValue, setSelectedValue] = useState(5);
  const [activeTab, setActiveTab] = useState('vouchers');

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const response = await axios.get('/api/vouchers');
        setVouchers(response.data);
      } catch (error) {
        console.error('Error fetching vouchers:', error);
      }
    };

    const fetchRedeemedVouchers = async () => {
      try {
        const response = await axios.get('/api/vouchers/redeemed');
        setRedeemedVouchers(response.data);
      } catch (error) {
        console.error('Error fetching redeemed vouchers:', error);
      }
    };

    // Initial fetch
    fetchVouchers();
    fetchRedeemedVouchers();

    // Set up intervals to fetch vouchers and redeemed vouchers every 5 seconds
    const vouchersInterval = setInterval(fetchVouchers, 5000);
    const redeemedVouchersInterval = setInterval(fetchRedeemedVouchers, 5000);

    // Clean up the intervals on component unmount
    return () => {
      clearInterval(vouchersInterval);
      clearInterval(redeemedVouchersInterval);
    };
  }, []);

  const handleGenerateVoucher = () => {
    const numericalValue = parseInt(selectedValue, 10);
    axios.post('/api/vouchers/generate', { value: numericalValue })
      .then(response => {
        setVouchers([response.data, ...vouchers]);
      })
      .catch(error => console.error('Error generating voucher:', error));
  };

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="adminPanel">
      <div className="headerContainer">
        <h1 className="adminPanelLogo">
          <img src="/stack-logo.png" alt="Stack Voucher Admin Panel Logo" />
        </h1>
        <div className="generateVoucherAction">
          <div className="select-wrapper">
            <select value={selectedValue} onChange={handleValueChange}>
              {[1, 5, 10, 20, 50, 100, 250, 500, 1000].map(amount => (
                <option key={amount} value={amount}>${amount}</option>
              ))}
            </select>
          </div>
          <button className="generateVoucherButton" onClick={handleGenerateVoucher}>
            Generate
          </button>
        </div>
      </div>
      <div className="tabContainer">
        <div
          className={`tabButton ${activeTab === 'vouchers' ? 'active' : ''}`}
          onClick={() => handleTabChange('vouchers')}
        >
          All Vouchers
        </div>
        <div
          className={`tabButton ${activeTab === 'redeemed' ? 'active' : ''}`}
          onClick={() => handleTabChange('redeemed')}
        >
          Redeemed Vouchers
        </div>
      </div>
      {activeTab === 'vouchers' ? (
        <VoucherTable vouchers={vouchers} />
      ) : (
        <RedeemedVouchersTable redeemedVouchers={redeemedVouchers} />
      )}
    </div>
  );
};

export default AdminPanel;